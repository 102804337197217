var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "j-modal",
    {
      attrs: {
        title: _vm.title,
        width: _vm.width,
        visible: _vm.visible,
        switchFullscreen: "",
        okButtonProps: { class: { "jee-hidden": _vm.disableSubmit } },
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c("tenant-form", {
        ref: "realForm",
        attrs: { disabled: _vm.disableSubmit, normal: "" },
        on: { ok: _vm.submitCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }